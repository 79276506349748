// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SearchBox.css */
.search-box-enter {
  opacity: 0;
}

.search-box-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.search-box-exit {
  opacity: 1;
}

.search-box-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
`, "",{"version":3,"sources":["webpack://./src/components/comman/Navbar/SearchBox.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,yBAAyB;AAC3B","sourcesContent":["/* SearchBox.css */\n.search-box-enter {\n  opacity: 0;\n}\n\n.search-box-enter-active {\n  opacity: 1;\n  transition: opacity 300ms;\n}\n\n.search-box-exit {\n  opacity: 1;\n}\n\n.search-box-exit-active {\n  opacity: 0;\n  transition: opacity 300ms;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
